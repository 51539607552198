
import { Component, Vue } from 'vue-property-decorator';
import AppHeaderImages from '@/components/offers/AppHeaderImages.vue';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

import { mdiImage } from '@mdi/js';

import { AdminService, FeatureService } from '@/services';
import { getLayoutData } from '@/utils/helperFunctions';
import { IForm } from '@/types/admin.types';

@Component({
  components: { AppHeaderImages }
})
export default class AdminSettings extends Vue {
  ActiveTenant = ActiveTenant;
  currentTenant = tenants[ActiveTenant];
  mdiImage = mdiImage;
  background = '';
  overlay = '';
  banner = '';
  onboardBackground = '';
  onboardOverlay = '';
  onboardBanner = '';
  titleTextColor = this.currentTenant.text.offersForYouDescriptionTitleColor;
  uploading = false;
  socialLinksData = getLayoutData();
  selectedCount = 0;

  form = {
    background: null,
    overlay: null,
    banner: null,
    onboardBackground: null,
    onboardOverlay: null,
    onboardBanner: null,
    footerText: this.socialLinksData.footerText ?? null,
    icon1: null,
    link1: this.socialLinksData.link1 ?? null,
    icon2: null,
    link2: this.socialLinksData.link2 ?? null,
    icon3: null,
    link3: this.socialLinksData.link3 ?? null,
    icon4: null,
    link4: this.socialLinksData.link4 ?? null
  } as IForm;

  async handleFormSubmit(): Promise<void> {
    try {
      this.uploading = true;
      let formData = new FormData();
      if (this.form.background) formData.append('background', this.form.background as any);
      if (this.form.overlay) formData.append('overlay', this.form.overlay as any);
      if (this.form.banner) formData.append('banner', this.form.banner as any);
      if (this.form.onboardBackground)
        formData.append('onboardBackground', this.form.onboardBackground as any);
      if (this.form.onboardOverlay) formData.append('onboardOverlay', this.form.onboardOverlay as any);
      if (this.form.onboardBanner) formData.append('onboardBanner', this.form.onboardBanner as any);
      if (this.form.footerText) formData.append('footerTextTemplate', this.form.footerText as any);
      if (this.form.icon1) formData.append('footerIcon1', this.form.icon1 as any);
      if (this.form.link1) formData.append('link1', this.form.link1 as any);
      if (this.form.icon2) formData.append('footerIcon2', this.form.icon2 as any);
      if (this.form.link2) formData.append('link2', this.form.link2 as any);
      if (this.form.icon3) formData.append('footerIcon3', this.form.icon3 as any);
      if (this.form.link3) formData.append('link3', this.form.link3 as any);
      if (this.form.icon4) formData.append('footerIcon4', this.form.icon4 as any);
      if (this.form.link4) formData.append('link4', this.form.link4 as any);
      await AdminService.uploadLayoutSettingsImages(formData);
    } catch (error: any) {
      this.$notifyError('Something went wrong');
    }

    // Update local storage
    await FeatureService.fetchLayoutSettingsImages();

    this.uploading = false;
    window.location.reload();
  }

  handleFormClear = (): void => {
    this.form.background = null;
    this.form.overlay = null;
    this.form.banner = null;
    this.form.onboardBackground = null;
    this.form.onboardOverlay = null;
    this.form.onboardBanner = null;
    this.form.footerText = null;
    this.form.icon1 = null;
    this.form.link1 = null;
    this.form.icon2 = null;
    this.form.link2 = null;
    this.form.icon3 = null;
    this.form.link3 = null;
    this.form.icon4 = null;
    this.form.link4 = null;
  };
}
